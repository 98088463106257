import React, { useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import { Tooltip } from "bootstrap";

import AdminLayout from '../../layouts/adminLayout/AdminLayout';
import Create from './modals/Create';
import Edit from './modals/Edit';
import Table from '../common/tables/Table';

import { BASE_URL } from '../../utils/constants';

const Pastors = () => {
	const [pastors, setPastors] = useState([])
	const [editID, setEditID] = useState(null);
	const user = useSelector((state) => state.user)
	const options = {
		method: "GET",
		headers: {
			"Content-type": "application/json; charset=UTF-8",
			"Authorization": `Token ${user?.token}`
		}
	}

	const createModal = useRef(null)
	const editModal = useRef(null)

	const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]')
	const tooltipList = [...tooltipTriggerList].map(tooltipTriggerEl => new Tooltip(tooltipTriggerEl))

	useEffect(() => {
		getPastors()
	}, [])

	const getPastors = () => {
		fetch(`${BASE_URL}/api/pastors`, options)
			.then(response => response.json())
			.then(data => {
				console.log("resp", data);
				setPastors(data.Data);
			})
	}

	const refreshPastorList = (edit = false) => {
		if (!edit) {
			createModal.current.hideModal();
		} else {
			editModal.current.hideModal();
		}
		resetEditID()
		getPastors()
	}

	const resetEditID = () => {
		setEditID(null)
	}

	useEffect(() => {
		if (editID == null) {
			return null;
		}
		editModal.current.showModal()
	}, [editID])

	const deletePastor = (id) => {
		if (window.confirm("Are you sure you want to delete this pastor?")) {
			const options = {
				method: "DELETE",
				headers: {
					"Authorization": `Token ${user?.token}`
				}
			}
			fetch(`${BASE_URL}/api/pastors/${id}`, options)
				.then(response => response.json())
				.then(data => {
					console.log("resp", data);
					if (data.Status) {
						refreshPastorList()
					}
				})
		}
	}

	const actions = (data) => {
		return (
			<React.Fragment>
				<div className="d-flex flex-row">
					<button className="icon-btn me-2" onClick={() => setEditID(data._id)}>
						<i className="text-warning fas fa-pencil"></i>
					</button>
					<button className="icon-btn" onClick={() => deletePastor(data._id)}>
						<i className="text-danger fas fa-trash"></i>
					</button>
				</div>
			</React.Fragment>
		)
	}

	return (
		<AdminLayout title="Pastors | Admin Panel">
			<div className="panel-box">
				<div className="head mb-4">
					<h3 className="title me-5 pt-2">Pastors</h3>
					<button className='icon-btn' type="button"
						data-bs-toggle="tooltip" data-bs-title="Add new Pastors" data-bs-delay="1000" onClick={() => createModal.current.showModal()}>
						<i className="fas fa-plus"></i>
					</button>
				</div>
				<div className='table-holder'>
					<Table title={['order', 'name', 'title', 'bio', 'action']} data={pastors} action={actions} />
				</div>
			</div>
			<Create modalRef={createModal} refresh={refreshPastorList} />
			{
				editID != null ?
					<Edit
						modalRef={editModal}
						refresh={refreshPastorList}
						id={editID}
						resetID={resetEditID}
					/>
					: ""
			}
		</AdminLayout>
	)
}

export default Pastors