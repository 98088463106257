import React from 'react'
import { useDispatch } from 'react-redux'

import Head from '../../components/head/Head';
import { BASE_URL } from '../../utils/constants';

import './Login.scss'

const Login = () => {
  const dispatch = useDispatch()

  const submitForm = (event) => {
    event.preventDefault()
    fetch(`${BASE_URL}/api/auth/login`, {
      method: "POST",
      body: JSON.stringify({
        email: document.getElementById('emailInput').value,
        password: document.getElementById('passwordInput').value,
      }),
      headers: {
        "Content-type": "application/json; charset=UTF-8"
      }
    })
      .then(response => response.json())
      .then(data => {
        if(data.Status){
          dispatch({
            type: "LOGIN_USER",
            data: data.Data.access_token
          })
        }else{
          alert(data.Message);
        }
      })
  }

  return (
    <div className='login-page'>
      <Head title="Login | Admin Panel" />
      <form id="loginForm" onSubmit={submitForm}>
        <div className="mb-3">
          <label htmlFor="emailInput" className="form-label">Enter Email:</label>
          <input type="email" className="form-control" id="emailInput" placeholder="Enter Email" />
        </div>
        <div className="mb-3">
          <label htmlFor="passwordInput" className="form-label">Enter Password:</label>
          <input type="password" className="form-control" id="passwordInput" placeholder="Enter Password" />
        </div>
        <button className="btn btn-light">Login to Admin Panel</button>
      </form>
    </div>
  )
}

export default Login