import React, { useState } from 'react'

import { PascalCase } from '../../../utils/helpers'

import './style.scss';

const Table = ({ title, data, action = () => { return void 0; } }) => {
	// const [serial, setSerial] = useState(1)
	let serial = 0;
	return (
		<div className='table-wrapper'>
			<table className="table table-hover">
				<thead className='table-secondary'>
					<tr>
						{
							title.map(element => {
								return (<th scope="col">{PascalCase(element)}</th>)
							})
						}
					</tr>
				</thead>
				<tbody>
					{
						data.length > 0 ?
							(data.map(value => {
								// setSerial(serial+1)
								serial += 1;
								return (
									<tr>
										{
											title.map(key => {
												if (key === 's_no') {
													return <td>{serial}</td>
												} else if (key === 'action') {
													return <td>{action(value)}</td>
												} else {
													return <td>{value[key]}</td>
												}
											})
										}
									</tr>
								)
							}))
							:
							(
								<tr>
									<td colSpan={title.length} className='text-center'>
										No Data Found. Try adding some.
									</td>
								</tr>
							)
					}
				</tbody>
			</table>
		</div>
	)
}

export default Table