import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import ReactQuill from 'react-quill';

import CustomModal from '../../common/modal/Modal';
import { BASE_URL } from '../../../utils/constants';
import { requiredStar } from '../../../utils/helpers'

import 'react-quill/dist/quill.snow.css';

const Create = ({ modalRef, refresh }) => {
    const user = useSelector((state) => state.user)
    const [bio, setBio] = useState('')

    const submitForm = (event) => {
        event.preventDefault();
        let fd = new FormData(document.getElementById('pastorForm'));
        // for (const pair of fd.entries()) {
        //     console.log(`data ${pair[0]}, ${pair[1]}`);
        // }
        const options = {
            method: "POST",
            body: fd,
            headers: {
                "Authorization": `Token ${user?.token}`
            }
        }
        fetch(`${BASE_URL}/api/pastors`, options)
            .then(response => response.json())
            .then(data => {
                console.log("resp", data);
                if (data.Status) {
                    document.getElementById('pastorForm').reset();
                    setBio('')
                    refresh()
                }
            })
    }

    const content = () => {
        return (
            <React.Fragment>
                <form id='pastorForm' onSubmit={submitForm}>
                    <div className="mb-3">
                        <label htmlFor="name" className="form-label">Name{requiredStar()} </label>
                        <input type="text" name="name" className="form-control" id="name" required />
                    </div>
                    <div className="mb-3">
                        <label htmlFor="title" className="form-label">Title{requiredStar()}</label>
                        <input type="text" name="title" className="form-control" id="title" required />
                    </div>
                    <div className="mb-3">
                        <label htmlFor="bio" className="form-label">Bio{requiredStar()}</label>
                        <input type="text" name="bio" value={bio} hidden readOnly />
                        <ReactQuill theme="snow" value={bio} onChange={setBio} />
                    </div>
                    <div className="mb-3">
                        <label htmlFor="image" className="form-label">Image{requiredStar()}</label>
                        <input type="file" name="image" className="form-control" id="image" />
                    </div>
                    <button className="btn btn-light w-100">Add Pastor</button>
                </form>
            </React.Fragment>
        )
    }

    return (
        <CustomModal modalRef={modalRef} title="Add Pastors" content={content}/>
    )
}

export default Create