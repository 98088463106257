import React, { useEffect, useState, useRef } from 'react'
import { useSelector } from 'react-redux'
import { Tooltip } from "bootstrap";

import AdminLayout from '../../layouts/adminLayout/AdminLayout'
import Create from './modals/Create';
import Edit from './modals/Edit';
import Table from '../common/tables/Table'

import { BASE_URL } from '../../utils/constants'

import './style.scss'

const Announcements = () => {
	const [announcements, setAnnouncements] = useState([]);
	const [editID, setEditID] = useState(null);
	const user = useSelector((state) => state.user)
	const options = {
		method: "GET",
		headers: {
			"Content-type": "application/json; charset=UTF-8",
			"Authorization": `Token ${user?.token}`
		}
	}
	const createModal = useRef(null)
	const editModal = useRef(null)
	const didMount = useRef(false);

	const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]')
	const tooltipList = [...tooltipTriggerList].map(tooltipTriggerEl => new Tooltip(tooltipTriggerEl))

	useEffect(() => {
		getAnnouncements()
	}, [])

	const getAnnouncements = () => {
		fetch(`${BASE_URL}/api/announcements`, options)
			.then(response => response.json())
			.then(data => {
				console.log("resp", data);
				setAnnouncements(data.Data);
			})
	}

	const refreshAnnouncementsList = (edit = false) => {
		if (!edit) {
			createModal.current.hideModal();
		} else {
			editModal.current.hideModal();
		}
		resetEditID()
		getAnnouncements()
	}

	const resetEditID = () => {
		setEditID(null)
	}

	useEffect(() => {
		if (editID == null) {
			return null;
		}
		editModal.current.showModal()
	}, [editID])

	const deleteAnnouncement = (id) => {
		if (window.confirm("Are you sure you want to delete this announcement?")) {
			const options = {
				method: "DELETE",
				headers: {
					"Authorization": `Token ${user?.token}`
				}
			}
			fetch(`${BASE_URL}/api/announcements/${id}`, options)
				.then(response => response.json())
				.then(data => {
					console.log("resp", data);
					if (data.Status) {
						refreshAnnouncementsList()
					}
				})
		}
	}

	const actions = (data) => {
		return (
			<React.Fragment>
				<div className="d-flex flex-row">
					<button className="icon-btn me-2" onClick={() => setEditID(data._id)}>
						<i className="text-warning fas fa-pencil"></i>
					</button>
					<button className="icon-btn" onClick={() => deleteAnnouncement(data._id)}>
						<i className="text-danger fas fa-trash"></i>
					</button>
				</div>
			</React.Fragment>
		)
	}

	return (
		<AdminLayout title="Announcements | Admin Panel">
			<div className="panel-box">
				<div className="head mb-4">
					<h3 className="title me-5 pt-2">Announcements</h3>
					<button className='icon-btn' type="button"
						data-bs-toggle="tooltip" data-bs-title="Add new Announcements" data-bs-delay="1000" onClick={() => createModal.current.showModal()}>
						<i className="fas fa-plus"></i>
					</button>
				</div>
				<div className='table-holder'>
					<Table title={['s_no', 'title', 'slug', 'date', 'html', 'action']} data={announcements} action={actions} />
				</div>
			</div>
			<Create modalRef={createModal} refresh={refreshAnnouncementsList} />
			{
				editID != null ?
					<Edit modalRef={editModal} refresh={refreshAnnouncementsList} id={editID} resetID={resetEditID} />
					: ""
			}

		</AdminLayout>
	)
}

export default Announcements