import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import ReactQuill from 'react-quill';

import CustomModal from '../../common/modal/Modal';
import { BASE_URL } from '../../../utils/constants';
import { convertDate, requiredStar } from '../../../utils/helpers';

import 'react-quill/dist/quill.snow.css';

const Edit = ({ modalRef, refresh, id, resetID }) => {
	const user = useSelector((state) => state.user)
	const [name, setName] = useState('')
	const [title, setTitle] = useState('');
	const [bio, setBio] = useState('')
	const [order, setOrder] = useState('')

	const extendHideFunctions = () => {
		resetID()
	}

	useEffect(() => {
		console.log(id);
		const options = {
			method: "GET",
			headers: { "Authorization": `Token ${user?.token}` }
		}
		fetch(`${BASE_URL}/api/pastors/${id}`, options)
			.then(response => response.json())
			.then(data => {
				console.log("resp", data);
				setName(data.Data.name)
				setTitle(data.Data.title)
				setBio(data.Data.bio)
				setOrder(data.Data.order)
			})
	}, [id])

	const submitForm = (event) => {
		event.preventDefault();
		let fd = new FormData(document.getElementById('editEventForm'));
		for (const pair of fd.entries()) {
			console.log(`data ${pair[0]}, ${pair[1]}`);
		}
		const options = {
			method: "PATCH",
			body: fd,
			headers: {
				"Authorization": `Token ${user?.token}`
			}
		}
		fetch(`${BASE_URL}/api/pastors/${id}`, options)
			.then(response => response.json())
			.then(data => {
				console.log("resp", data);
				if (data.Status) {
					document.getElementById('editEventForm').reset();
					setBio('')
					refresh(true)
				}
			})
	}

	const content = () => {
		return (
			<React.Fragment>
				<form id='editEventForm' onSubmit={submitForm}>
					<div className="mb-3">
						<label htmlFor="name" className="form-label">Name{requiredStar()} </label>
						<input type="text" name="name" className="form-control" id="name" value={name} onChange={(e) => setName(e.target.value)} required />
					</div>
					<div className="mb-3">
						<label htmlFor="title" className="form-label">Title{requiredStar()}</label>
						<input type="text" name="title" className="form-control" value={title} onChange={(e) => setTitle(e.target.value)} id="title" required />
					</div>
					<div className="mb-3">
						<label htmlFor="bio" className="form-label">Bio{requiredStar()}</label>
						<input type="text" name="bio" value={bio} hidden readOnly />
						<ReactQuill theme="snow" value={bio} onChange={setBio} />
					</div>
					<div className="mb-3">
						<label htmlFor="image" className="form-label">Image{requiredStar()}</label>
						<input type="file" name="image" className="form-control" id="image" />
					</div>
					<div className="mb-3">
						<label htmlFor="order" className="form-label">Order{requiredStar()}</label>
						<input type="text" name="order" className="form-control" value={order} onChange={(e) => setOrder(e.target.value)} id="order" required />
					</div>
					<button className="btn btn-light w-100">Update Pastor</button>
				</form>
			</React.Fragment>
		)
	}

	return (
		<CustomModal
			modalRef={modalRef}
			title="Edit Event"
			content={content}
			extendHideFunctions={extendHideFunctions}
		/>
	)
}

export default Edit