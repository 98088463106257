import React from 'react'
import AdminNavBar from '../../admin/navbar/AdminNavBar'
import Head from '../../components/head/Head';

import './AdminLayout.scss'

import 'bootstrap/dist/css/bootstrap.min.css';
// import 'bootstrap/dist/js/bootstrap.min.js';
import "bootstrap";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const AdminLayout = ({ title, children }) => {
	return (
		<div className='admin-layout'>
			<Head title={title} />
			<main>
				<div className="left-panel">
					<AdminNavBar />
				</div>
				<div className="right-panel">
					{children}
				</div>
			</main>
		</div>
	)
}

export default AdminLayout