import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Tooltip } from "bootstrap";

import AdminLayout from '../../layouts/adminLayout/AdminLayout'
import Table from '../common/tables/Table';

import { BASE_URL } from '../../utils/constants';

const PrayerRequests = () => {
	const [requests, setRequests] = useState([]);
	const user = useSelector((state) => state.user)
	const options = {
		method: "GET",
		headers: {
			"Content-type": "application/json; charset=UTF-8",
			"Authorization": `Token ${user?.token}`
		}
	}
	const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]')
	const tooltipList = [...tooltipTriggerList].map(tooltipTriggerEl => new Tooltip(tooltipTriggerEl))

	useEffect(() => {
		getPrayerRequests()
	}, [])

	const getPrayerRequests = () => {
		fetch(`${BASE_URL}/api/prayerrequests`, options)
			.then(response => response.json())
			.then(data => {
				console.log("resp", data);
				setRequests(data.Data);
			})
	}

	const updatePrayerRequest = (id) => {
		let options = {
			method: "PATCH",
			body: JSON.stringify({
				attended: true
			}),
			headers: {
				"Content-type": "application/json; charset=UTF-8",
				"Authorization": `Token ${user?.token}`
			}
		}
		fetch(`${BASE_URL}/api/prayerrequests/${id}`, options)
			.then(response => response.json())
			.then(data => {
				console.log("resp", data);
				getPrayerRequests()
			})
	}

	const actions = (data) => {
		return (
			<React.Fragment>
				<div style={{ fontSize: "1.3rem" }}>
					{
						!data.attended ?
							<button className="icon-btn" onClick={() => updatePrayerRequest(data._id)}>
								<i className="far fa-square"></i>
							</button>
							:
							<button className="icon-btn" disabled>
								<i className="text-black far fa-square-check"></i>
							</button>
					}
				</div>
			</React.Fragment>
		)
	}

	return (
		<AdminLayout title="Prayer Requests | Admin Panel">
			<div className="panel-box">
				<div className="head mb-4">
					<h3 className="title me-5 pt-2">Prayer Requests</h3>
				</div>
				<div className='table-holder'>
					<Table title={['s_no', 'name', 'email', 'mobile', 'request', 'action']} data={requests} action={actions} />
				</div>
			</div>
		</AdminLayout>
	)
}

export default PrayerRequests