import React from "react"
import { Router } from "@reach/router"
import AdminControl from "../../admin/AdminControl"

const app = () => {
	return (
		<Router basepath="/flag-admin">
			<AdminControl path="*" />
		</Router>
	)
}

export default app