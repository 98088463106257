import React, { useEffect, useState, useRef } from 'react'
import { useSelector } from 'react-redux';
import { Tooltip } from "bootstrap";

import AdminLayout from '../../layouts/adminLayout/AdminLayout';
import Create from './modals/Create';
import Table from '../common/tables/Table';

import { BASE_URL } from '../../utils/constants';

const Youtube = () => {
  const [excludedPlaylists, setExcludedPlaylists] = useState([])
  const user = useSelector((state) => state.user)
  const options = {
    method: "GET",
    headers: {
      "Content-type": "application/json; charset=UTF-8",
      "Authorization": `Token ${user?.token}`
    }
  }

  const createModal = useRef(null)

  const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]')
	const tooltipList = [...tooltipTriggerList].map(tooltipTriggerEl => new Tooltip(tooltipTriggerEl))

  useEffect(() => {
    getExcludedPlaylists()
  }, [])

  const getExcludedPlaylists = () => {
    fetch(`${BASE_URL}/api/youtube/excludedPlaylist`, options)
      .then(response => response.json())
      .then(data => {
        console.log("resp", data);
        setExcludedPlaylists(data.Data);
      })
  }

  const resetCachedPlaylists = () => {
    fetch(`${BASE_URL}/api/youtube/resetPlaylistCache`, options)
      .then(response => response.json())
      .then(data => {
        console.log("resp", data);
      })
  }

  const deleteExcludedPlaylist = (id) => {
    let options = {
      method: "DELETE",
      headers: {
        "Content-type": "application/json; charset=UTF-8",
        "Authorization": `Token ${user?.token}`
      }
    }
    if (window.confirm("Are you sure you want to delete?")) {
      fetch(`${BASE_URL}/api/youtube/excludedPlaylist/${id}`, options)
        .then(response => response.json())
        .then(data => {
          console.log("resp", data);
          getExcludedPlaylists()
        })
    }
  }

  const refreshExcludedPlaylist = () => {
    createModal.current.hideModal();
    getExcludedPlaylists()
  }

  const actions = (data) => {
    return (
      <React.Fragment>
        <div className="d-flex flex-row">
          <button className="icon-btn" onClick={() => deleteExcludedPlaylist(data._id)}>
            <i className="text-danger fas fa-trash"></i>
          </button>
        </div>
      </React.Fragment>
    )
  }

  return (
    <AdminLayout title="Pastors | Admin Panel">
      <div className="panel-box">
        <div className="head mb-4">
          <h3 className="title me-5 pt-2">Excluded Playlists</h3>
          <button className='icon-btn' type="button"
            data-bs-toggle="tooltip" data-bs-title="Exclude Playlists" data-bs-delay="1000" onClick={() => createModal.current.showModal()}
          >
            <i className="fas fa-plus"></i>
          </button>
          <button
            type='button'
            className='ms-5 btn btn-outline-dark'
            onClick={() => resetCachedPlaylists()}
          >
            Reset Cache
          </button>
        </div>
        <div className='table-holder'>
          <Table title={['s_no', 'id', 'action']} data={excludedPlaylists} action={actions} />
        </div>
      </div>
      <Create modalRef={createModal} refresh={refreshExcludedPlaylist} />
    </AdminLayout>
  )
}

export default Youtube