import React, { useRef, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import ReactQuill from 'react-quill';

import CustomModal from '../../common/modal/Modal';
import { BASE_URL } from '../../../utils/constants';
import { requiredStar } from '../../../utils/helpers'

import 'react-quill/dist/quill.snow.css';

const Create = ({ modalRef, refresh }) => {
    const user = useSelector((state) => state.user)
    const [html, setHtml] = useState('')

    const submitForm = (event) => {
        event.preventDefault();
        let fd = new FormData(document.getElementById('eventForm'));
        // for (const pair of fd.entries()) {
        //     console.log(`data ${pair[0]}, ${pair[1]}`);
        // }
        const options = {
            method: "POST",
            body: fd,
            headers: {
                "Authorization": `Token ${user?.token}`
            }
        }
        fetch(`${BASE_URL}/api/events`, options)
            .then(response => response.json())
            .then(data => {
                console.log("resp", data);
                if (data.Status) {
                    document.getElementById('eventForm').reset();
                    setHtml('')
                    refresh()
                }
            })
    }

    const content = () => {
        return (
            <React.Fragment>
                <form id='eventForm' onSubmit={submitForm}>
                    <div className="mb-3">
                        <label htmlFor="title" className="form-label">Title{requiredStar()} </label>
                        <input type="text" name="title" className="form-control" id="title" required />
                    </div>
                    <div className="mb-3">
                        <label htmlFor="slug" className="form-label">Slug</label>
                        <input type="text" name="slug" className="form-control" id="slug" />
                    </div>
                    <div className="mb-3">
                        <label htmlFor="date" className="form-label">Date{requiredStar()}</label>
                        <input type="date" name="date" className="form-control" id="date" required />
                    </div>
                    <div className="mb-3">
                        <label htmlFor="html" className="form-label">HTML{requiredStar()}</label>
                        <input type="text" name="html" value={html} hidden readOnly />
                        <ReactQuill theme="snow" value={html} onChange={setHtml} />
                    </div>
                    <div className="mb-3">
                        <label htmlFor="thumb" className="form-label">Thumbnail{requiredStar()}</label>
                        <input type="file" name="thumb" className="form-control" id="thumb" required />
                    </div>
                    <div className="mb-3">
                        <label htmlFor="galleryImgs" className="form-label">Gallery Images{requiredStar()}</label>
                        <input type="file" name="galleryImgs" className="form-control" id="galleryImgs" required multiple />
                    </div>
                    <button className="btn btn-light w-100">Add Event</button>
                </form>
            </React.Fragment>
        )
    }

    return (
        <CustomModal modalRef={modalRef} title="Add Events" content={content}/>
    )
}

export default Create