import React from 'react'
import { useSelector } from 'react-redux'

import CustomModal from '../../common/modal/Modal';
import { BASE_URL } from '../../../utils/constants';
import { requiredStar } from '../../../utils/helpers'

const Create = ({ modalRef, refresh }) => {
    const user = useSelector((state) => state.user)

    const submitForm = (event) => {
        event.preventDefault();
        let fd = new FormData(document.getElementById('playlistForm'));
        for (const pair of fd.entries()) {
            console.log(`data ${pair[0]}, ${pair[1]}`);
        }
        const options = {
            method: "POST",
            body: fd,
            headers: {
                "Authorization": `Token ${user?.token}`
            }
        }
        fetch(`${BASE_URL}/api/youtube/excludedPlaylist`, options)
            .then(response => response.json())
            .then(data => {
                console.log("resp", data);
                if (data.Status) {
                    document.getElementById('playlistForm').reset();
                    refresh()
                }
            })
    }

    const content = () => {
        return (
            <React.Fragment>
                <form id='playlistForm' onSubmit={submitForm}>
                    <div className="mb-3">
                        <label htmlFor="id" className="form-label">playlist ID{requiredStar()} </label>
                        <input type="text" name="id" className="form-control" id="id" required />
                    </div>
                    <button type='submit' className="btn btn-light w-100">Exclude Playlist</button>
                </form>
            </React.Fragment>
        )
    }

    return (
        <CustomModal modalRef={modalRef} title="Exclude Playlist" content={content}/>
    )
}

export default Create