import React, { useEffect } from 'react'
import { Router, useLocation } from "@reach/router"
import { useSelector } from 'react-redux'
import { Link } from 'gatsby'

import Login from './login/Login'
import Home from './home/Home'
import Announcements from  './announcements/Announcements'
import Events from './events/Events'
import Pastors from './pastors/Pastors'
import PrayerRequests from './prayerRequests/PrayerRequests'
import Youtube from './youtube/youtube'

const MainRouter = () => {
  const user = useSelector((state) => state.user)
  const location = useLocation();

  useEffect(() => {
    if (!user.isloggedin) {
      document.getElementById('loginTag').click();
    } else if (location.pathname === "/flag-admin/login") {
      document.getElementById('homeTag').click();
    }
  }, [user])

  return (
    <>
      <Router>
        <Announcements path='/announcements' />
        <Events path='/events' />
        <Pastors path='/pastors' />
        <PrayerRequests path='/prayer_requests' />
        <Login path='/login' />
        <Youtube path="/youtube" />
        <Home path='/' />
      </Router>
      <Link to="/flag-admin/login" hidden id='loginTag'></Link>
      <Link to="/flag-admin" hidden id='homeTag'></Link>
    </>
  )
}

export default MainRouter

