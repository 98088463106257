import React, { useRef, useEffect, useState } from 'react'
import { Modal } from "bootstrap";

const CustomModal = ({
	modalRef,
	title,
	content = () => { return void 0 },
	extendShowFunctions = () => { return void 0 },
	extendHideFunctions = () => { return void 0 },
}) => {
	const modal = useRef(null)
	const [modalSize, setModalSize] = useState('modal-md')

	useEffect(() => {
		modalRef.current = {
			showModal: showModal,
			hideModal: hideModal
		}
	}, [])

	const showModal = () => {
		const modalEle = modal.current;
		const bsModal = new Modal(modalEle, {
			backdrop: "static",
			keyboard: false,
		});
		bsModal.show();
		extendShowFunctions()
	}

	const hideModal = () => {
		const modalEle = modal.current;
		const bsModal = Modal.getInstance(modalEle);
		try{
			bsModal.hide();
		}catch(e){
			console.error(e)
		}
		extendHideFunctions()
	}

	return (
		<div className="modal" ref={modal} tabIndex="-1">
			<div className={`modal-dialog ${modalSize}`}>
				<div className="modal-content">
					<div className="modal-header">
						<h5 className="modal-title">{title}</h5>
						<div>
							<button
								className="icon-btn"
								onClick={() => setModalSize('modal-md')}>sm</button>
							<button
								className="icon-btn"
								onClick={() => setModalSize('modal-lg')}>md</button>
							<button
								className="icon-btn me-4"
								onClick={() => setModalSize('modal-xl')}>lg</button>
							<button
								type="button"
								className="btn-close"
								onClick={() => hideModal()}
								aria-label="Close"
							></button>
						</div>
					</div>
					<div className="modal-body mx-5">
						{content()}
					</div>
				</div>
			</div>
		</div>
	)
}

export default CustomModal