import React from "react"
import { Provider } from 'react-redux'

import store from '../redux/store/store'
import MainRouter from "./router"

const AdminControl = () => {
  return (
    <Provider store={store}>
      <MainRouter />
    </Provider>
  )
}

export default AdminControl

