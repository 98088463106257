import React, { useRef, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import ReactQuill from 'react-quill';

import CustomModal from '../../common/modal/Modal';
import { BASE_URL } from '../../../utils/constants';
import { convertDate, requiredStar } from '../../../utils/helpers';

import 'react-quill/dist/quill.snow.css';

const Edit = ({ modalRef, refresh, id, resetID }) => {
	const user = useSelector((state) => state.user)
	const [title, setTitle] = useState('');
	const [slug, setSlug] = useState('');
	const [date, setDate] = useState('');
	const [html, setHtml] = useState('')

	const extendHideFunctions = () => {
		resetID()
	}

	useEffect(() => {
		console.log(id);
		const options = {
			method: "GET",
			headers: { "Authorization": `Token ${user?.token}` }
		}
		fetch(`${BASE_URL}/api/events/id/${id}`, options)
			.then(response => response.json())
			.then(data => {
				console.log("resp", data);
				setTitle(data.Data.title)
				setSlug(data.Data.slug)
				setDate(data.Data.date)
				setHtml(data.Data.html)
			})
	}, [id])

	const submitForm = (event) => {
		event.preventDefault();
		let fd = new FormData(document.getElementById('editEventForm'));
		for (const pair of fd.entries()) {
			console.log(`data ${pair[0]}, ${pair[1]}`);
		}
		const options = {
			method: "PATCH",
			body: fd,
			headers: {
				"Authorization": `Token ${user?.token}`
			}
		}
		fetch(`${BASE_URL}/api/events/${id}`, options)
			.then(response => response.json())
			.then(data => {
				console.log("resp", data);
				if (data.Status) {
					document.getElementById('editEventForm').reset();
					setHtml('')
					refresh(true)
				}
			})
	}

	const content = () => {
		return (
			<React.Fragment>
				<form id='editEventForm' onSubmit={submitForm}>
					<div className="mb-3">
						<label htmlFor="title" className="form-label">Title{requiredStar()} </label>
						<input type="text" name="title" className="form-control" id="title" required value={title} onChange={(e) => setTitle(e.target.value)} />
					</div>
					<div className="mb-3">
						<label htmlFor="slug" className="form-label">Slug</label>
						<input type="text" name="slug" className="form-control" id="slug" value={slug} onChange={(e) => setSlug(e.target.value)} />
					</div>
					<div className="mb-3">
						<label htmlFor="date" className="form-label">Date{requiredStar()}</label>
						<input type="date" name="date" className="form-control" id="date" required value={convertDate(date)} onChange={(e) => setDate(e.target.value)} />
					</div>
					<div className="mb-3">
						<label htmlFor="html" className="form-label">HTML{requiredStar()}</label>
						<input type="text" name="html" value={html} hidden readOnly />
						<ReactQuill theme="snow" value={html} onChange={setHtml} />
					</div>
					<div className="mb-3">
						<label htmlFor="thumb" className="form-label">Thumbnail</label>
						<input type="file" name="thumb" className="form-control" id="thumb" />
					</div>
					<div className="mb-3">
						<label htmlFor="galleryImgs" className="form-label">Gallery Images{requiredStar()}</label>
						<input type="file" name="galleryImgs" className="form-control" id="galleryImgs" required multiple />
					</div>
					<button className="btn btn-light w-100">Update Event</button>
				</form>
			</React.Fragment>
		)
	}

	return (
		<CustomModal
			modalRef={modalRef}
			title="Edit Event"
			content={content}
			extendHideFunctions={extendHideFunctions}
		/>
	)
}

export default Edit