import jwt_decode from "jwt-decode";

function getDecodedValue() {
    try {
        if (typeof window !== 'undefined' && window.localStorage.hasOwnProperty("flag_token")) {
            const decoded = jwt_decode(
                window.localStorage.getItem("flag_token")
            );
            return decoded
            // const currentTime = Date.now() / 1000;
            // if (decoded.exp < currentTime) {
            // 	return {
            // 		...state,
            // 		isAuthenticated: false,
            // 		user: null
            // 	}
            // }
        } else {
            return null;
        }
    } catch(e) {
        return null;
    }
}

function getAccessToken(){
    try{
        let token = typeof window !== 'undefined' ? window.localStorage.getItem("flag_token"): ""
        return token
    }catch(e){
        return null
    }
}

let initialState = {
    decoded: getDecodedValue(),
    token: getAccessToken(),
    isloggedin: JSON.parse(typeof window !== 'undefined' ? window.localStorage.getItem("isloggedin") : "{}") || false,
};

const LoginReducer = (state = initialState, action) => {
    switch (action.type) {
        case "LOGIN_USER":
            console.log("came to login reducer");
            window.localStorage.setItem("flag_token", action.data);
            window.localStorage.setItem("isloggedin", JSON.stringify(true));
            return Object.assign({}, state, {
                decoded: null,
                token: action.data,
                isloggedin: true,
            });
        case "LOGOUT_USER":
            console.log("came to logout reducer");
            window.localStorage.removeItem("flag_token");
            window.localStorage.setItem("isloggedin", JSON.stringify(false));
            return Object.assign({}, state, {
                decoded: null,
                token: null,
                isloggedin: false,
            });
        default:
            return state;
    }
};

export default LoginReducer;
