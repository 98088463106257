import React from 'react'
import AdminLayout from '../../layouts/adminLayout/AdminLayout'

const Home = () => {
	return (
		<AdminLayout title="Home | Admin Panel">
			<div className="panel-box">
				Home
			</div>
		</AdminLayout>
	)
}

export default Home