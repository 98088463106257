import React from 'react'
import { Link } from "gatsby"
import { useDispatch } from 'react-redux'

import './AdminNavBar.scss'

const AdminNavBar = () => {
  const dispatch = useDispatch()

  return (
    <nav className='admin-navbar'>
      <Link to="/flag-admin" activeClassName='active'>Home</Link>
      <Link to="/flag-admin/announcements" activeClassName='active'>Announcements</Link>
      <Link to="/flag-admin/events" activeClassName='active'>Events</Link>
      <Link to="/flag-admin/pastors" activeClassName='active'>Pastors</Link>
      <Link to="/flag-admin/prayer_requests" activeClassName='active'>Prayer Requests</Link>
      <Link to="/flag-admin/youtube" activeClassName='active'>Sermon Config</Link>
      <Link to="/" activeClassName='active'>Home page</Link>
      <button onClick={() => dispatch({type: "LOGOUT_USER"})}>Logout</button>
    </nav>
  )
}

export default AdminNavBar